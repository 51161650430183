<template>
  <div>
    <Header :showTitle="false" :showLogo="true" :showBackButton="true" />

    <section class="page-content page-anim2">
      <div class="container pb-3">
        <h1 v-show="!loading" class="title section-title category text-blue">
          {{ t("Novidades") }}
        </h1>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="!loading"
            class="cards-home-holder mt-1-mobile mt-1-tablet mt-2-desktop"
          >
            <div
              @click="$router.push(`/template/${template.id}`)"
              v-for="template in latestTemplates"
              :key="template.id"
              class="category-card"
            >
              <div class="img-holder">
                <img class="bg-img" :src="template.coverImage" loading="lazy" />
              </div>
              <p class="card-title text-blue">{{ template.title }}</p>
            </div>
          </div>
        </transition>

        <div v-show="loading" class="flex-center mt-2">
          <div class="loading-spinner blue"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
export default {
  name: "Novidades",
  components: {
    Header,
  },
  data() {
    return {
      loading: true,
      latestTemplates: [],
    };
  },
  created() {
    this.getLatestTemplates();
  },
  methods: {
    getLatestTemplates() {
      http
        .get(`template?take=100`, authentication.getHeaders())
        .then((response) => {
          this.latestTemplates = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title.category {
  margin-top: 22px;
}
</style>
